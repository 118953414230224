@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

/* Global Styles */
body {
    font-family: 'Inter', sans-serif;
    background-color: #F2F4F7;
    color: #000000;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* IE10+ */
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin-bottom: 1rem;
}

p, span, li, a {
    font-weight: 400;
    margin-bottom: 0.5rem;
}

button {
    font-weight: 400;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    border: 2px solid #5C6370;
    background-color: transparent;
    color: #000000;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

button:hover {
    background-color: #5C6370;
    color: #FFFFFF;
}

/* Input Styles */
.input {
    width: 100%;
    border: 2px solid #5C6370;
    border-radius: 0.5rem;
    padding: 0.75rem;
    color: #000000;
    background-color: #FFFFFF;
    margin-bottom: 1rem;
    appearance: none;
}

/* Dropdown Option Styles */
select.input option {
    background-color: #FFFFFF;
    color: #000000;
}

/* Card Styles */
.card {
    background-color: #FFFFFF;
    border: 2px solid #5C6370;
    border-radius: 0.5rem;
    padding: 2rem;
    margin-bottom: 1.5rem;
}

.card-influencer {
    background-color: #FFFFFF;
    border: 2px solid #5C6370;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
}
/* Layout Styles */
.spacious {
    padding: 2rem;
    margin: 2rem;
}

.bg-white {
    background-color: #FFFFFF;
}

.text-black {
    color: #000000;
}

/* Custom Dropdown Arrow */
.custom-dropdown-arrow {
    fill: currentColor;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

/* Modal Styles */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2rem;
    border: 2px solid #5C6370;
    border-radius: 0.5rem;
    outline: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
}

.btn-icon-small {
    padding: 0.25rem; /* Smaller padding */
    border: 1.5px solid #000;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem; /* Smaller width */
    height: 1.5rem; /* Smaller height */
    /*font-weight: bold;*/
}

.btn-icon-href {
    padding: 0.25rem; /* Smaller padding */
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem; /* Smaller width */
    height: 1.5rem; /* Smaller height */
    /*font-weight: bold;*/
}

/* CustomToggle styles */
input:checked + .block {
    background-color: #000;
}

input:checked + .block .dot {
    transform: translateX(100%);
    background-color: #fff;
}



.tab {
    cursor: pointer;
    padding: 10px 20px;
    margin-right: 10px;
    background: #f3f3f3;
    border-radius: 4px;
    transition: background 0.3s;
}

.tab-active {
    background: #000;
    color: #fff;
}

.tab:hover {
    background: #ddd;
}

.content-list {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.content-item {
    margin-bottom: 10px;
}

.loader {
    text-align: center;
}

.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.line-clamp-5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal;
}

@keyframes flash {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.animate-flash {
    animation: flash 1s infinite;
}