.juggling-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 150px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
}

.ball {
    width: 20px; /* Size of the ball */
    height: 20px; /* Size of the ball */
    background-color: #000; /* Ball color */
    border-radius: 50%;
    position: absolute;
    animation: juggle 2s infinite;
}

.ball-1 {
    animation-delay: 0s;
}

.ball-2 {
    animation-delay: 0.33s;
}

.ball-3 {
    animation-delay: 0.66s;
}

@keyframes juggle {
    0% {
        transform: translate(-50px, 50px);
    }
    25% {
        transform: translate(0px, -30px) scale(1.1);
    }
    50% {
        transform: translate(50px, 50px);
    }
    75% {
        transform: translate(0px, -30px) scale(1.1);
    }
    100% {
        transform: translate(-50px, 50px);
    }
}